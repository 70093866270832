import { render, staticRenderFns } from "./Viewrecord.vue?vue&type=template&id=3e991030&scoped=true"
import script from "./Viewrecord.vue?vue&type=script&lang=js"
export * from "./Viewrecord.vue?vue&type=script&lang=js"
import style0 from "./Viewrecord.vue?vue&type=style&index=0&id=3e991030&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e991030",
  null
  
)

export default component.exports